// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.cubeContainer {
  --cube-face-height: 100vh;
  width: 100vw;
  height: var(----cube-face-height);
  perspective: calc(100vw * 2);
  position: fixed;
}
.cubeContainer .cube {
  width: 100vw;
  height: var(----cube-face-height);
  position: relative;
  transform-style: preserve-3d;
  transform: translateZ(calc((100vw / 2) * -1));
}
.cubeContainer .cube__full--screen {
  width: 100vw;
  height: var(----cube-face-height);
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.cubeContainer .cube.show-right {
  transform: translateZ(calc((100vw / 2) * -1)) rotateY(-90deg);
}
.cubeContainer .cube.show-left {
  transform: translateZ(calc((100vw / 2) * -1)) rotateY(90deg);
}
.cubeContainer .cube__face {
  position: absolute;
  width: 100vw;
  height: var(----cube-face-height);
}
.cubeContainer .cube__face--front {
  transform: rotateY(0deg) translateZ(calc(100vw / 2));
}
.cubeContainer .cube__face--right {
  transform: rotateY(90deg) translateZ(calc(100vw / 2));
}
.cubeContainer .cube__face--left {
  transform: rotateY(-90deg) translateZ(calc(100vw / 2));
}
`, "",{"version":3,"sources":["webpack://./src/components/CubeSlider/CubeSlider.less"],"names":[],"mappings":"AAGA;EACE,yBAAA;EACA,YAAA;EACA,iCAAA;EACA,4BAAA;EACA,eAAA;AAFF;AAHA;EAQI,YAAA;EACA,iCAAA;EACA,kBAAA;EACA,4BAAA;EACA,6CAAA;AAFJ;AAVA;EAgBI,YAAA;EACA,iCAAA;EACA,sBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;AAHJ;AAnBA;EAyBsB,6DAAA;AAHtB;AAtBA;EA0BsB,4DAAA;AADtB;AAzBA;EA6BI,kBAAA;EACA,YAAA;EACA,iCAAA;AADJ;AA9BA;EAkCwB,oDAAA;AADxB;AAjCA;EAmCwB,qDAAA;AACxB;AApCA;EAoCwB,sDAAA;AAGxB","sourcesContent":["@cube-face-width: 100vw;\n@cube-background-color: #000;\n\n.cubeContainer {\n  --cube-face-height: 100vh;\n  width: @cube-face-width;\n  height: var(----cube-face-height);\n  perspective: calc(@cube-face-width * 2);  \n  position: fixed;\n\n  .cube {\n    width: @cube-face-width;\n    height: var(----cube-face-height);\n    position: relative;\n    transform-style: preserve-3d;\n    transform: translateZ(calc((@cube-face-width / 2) * -1));\n  }\n  \n  .cube__full--screen {\n    width: @cube-face-width;\n    height: var(----cube-face-height);\n    background-color: @cube-background-color;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n  }\n\n  .cube.show-right  { transform: translateZ(calc((@cube-face-width / 2) * -1)) rotateY( -90deg); }\n  .cube.show-left   { transform: translateZ(calc((@cube-face-width / 2) * -1)) rotateY(  90deg); }\n\n  .cube__face {\n    position: absolute;\n    width: @cube-face-width;\n    height: var(----cube-face-height);\n  }\n\n  .cube__face--front  { transform: rotateY(  0deg) translateZ(calc(@cube-face-width / 2)); }\n  .cube__face--right  { transform: rotateY( 90deg) translateZ(calc(@cube-face-width / 2)); }\n  .cube__face--left   { transform: rotateY(-90deg) translateZ(calc(@cube-face-width / 2)); }\n\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
