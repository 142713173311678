// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.containerWebProjects {
  position: absolute;
  top: 15px;
  left: 20px;
  padding: 5px 0px 5px 5px;
}
@media only screen and (max-width: 670px) {
  .containerWebProjects {
    top: 10px;
    left: 15px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/pages/Welcome/MenuWebProjects/MenuWebProjects.less"],"names":[],"mappings":"AACA;EACI,kBAAA;EACA,SAAA;EACA,UAAA;EACA,wBAAA;AAAJ;AAGA;EACI;IACI,SAAA;IACA,UAAA;EADN;AACF","sourcesContent":["\n.containerWebProjects {\n    position: absolute;\n    top: 15px;\n    left: 20px;\n    padding: 5px 0px 5px 5px;\n}\n\n@media only screen and (max-width: 670px) {\n    .containerWebProjects  {\n        top: 10px;\n        left: 15px;\n    }\n  }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
