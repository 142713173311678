// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.craft__container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: WhiteSmoke;
  overflow: scroll;
}
.craft__container .title {
  position: absolute;
  top: 30px;
  left: 50px;
  font-size: 0.7em;
  font-weight: 300;
  letter-spacing: 5px;
  color: DimGray;
}
.craft__container .fourOFour {
  font-size: 1em;
  font-weight: 300;
  letter-spacing: 5px;
  font-style: oblique;
  color: white;
  text-shadow: 1px 1px 5px rgba(105, 105, 105, 0.3);
}
`, "",{"version":3,"sources":["webpack://./src/pages/Craft/Craft.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,4BAAA;EACA,gBAAA;AACJ;AAbA;EAeQ,kBAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AACR;AAtBA;EAyBQ,cAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;EACA,iDAAA;AAAR","sourcesContent":[".craft__container {\n    position: absolute;\n    z-index: 1;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: WhiteSmoke;\n    overflow: scroll;\n\n    .title { \n        position: absolute;\n        top: 30px;\n        left: 50px;\n        font-size: .7em;\n        font-weight: 300;\n        letter-spacing: 5px;\n        color: DimGray;\n    }\n\n    .fourOFour { \n        font-size: 1em;\n        font-weight: 300;\n        letter-spacing: 5px;\n        font-style: oblique;\n        color: white;\n        text-shadow: 1px 1px 5px rgba(DimGray, .3);\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
