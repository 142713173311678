// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./vanStaenStudio.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.music__container {
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100vw;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: WhiteSmoke;
  overflow: scroll;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.music__container .title {
  position: absolute;
  top: 30px;
  left: 50px;
  font-size: 0.7em;
  font-weight: 300;
  letter-spacing: 5px;
  color: DimGray;
}
`, "",{"version":3,"sources":["webpack://./src/pages/Music/Music.less"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,UAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,4BAAA;EACA,gBAAA;EACA,mDAAA;EACA,sBAAA;EACA,4BAAA;EACA,2BAAA;AACJ;AAjBA;EAmBQ,kBAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;EACA,gBAAA;EACA,mBAAA;EACA,cAAA;AACR","sourcesContent":[".music__container {\n    position: absolute;\n    z-index: 1;\n    top: 0;\n    left: 0;\n    width: 100vw;\n    text-align: center;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    background-color: WhiteSmoke;\n    overflow: scroll;\n    background: url(\"./vanStaenStudio.jpg\");\n    background-size: cover;\n    background-repeat: no-repeat;\n    background-position: center;\n\n    .title { \n        position: absolute;\n        top: 30px;\n        left: 50px;\n        font-size: .7em;\n        font-weight: 300;\n        letter-spacing: 5px;\n        color: DimGray;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
