// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  margin: 0;
  font-family: "Helvetica Neue";
}
.App {
  font-size: 25px;
  background-color: #000;
  color: white;
  min-height: 100vh;
  /* Fallback for browsers that do not support Custom Properties */
  min-height: calc(var(--vh, 1vh) * 100);
}
.link {
  color: rgba(255, 255, 255, 0.95);
  cursor: pointer;
  text-decoration: none;
  transition: color 0.3s;
}
.link:hover {
  color: rgba(255, 255, 255, 0.75);
  text-decoration: underline;
}
.pointer {
  cursor: pointer;
}
.pointer:hover {
  font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./src/App.less"],"names":[],"mappings":"AAAA;EACE,SAAA;EACA,6BAAA;AACF;AAGA;EACE,eAAA;EACA,sBAAA;EACA,YAAA;EACA,iBAAA;EADA,gEAAgE;EAGhE,sCAAA;AADF;AAIA;EACE,gCAAA;EACA,eAAA;EACA,qBAAA;EACA,sBAAA;AAFF;AAKA;EACE,gCAAA;EACA,0BAAA;AAHF;AAMA;EACE,eAAA;AAJF;AAOA;EACE,gBAAA;AALF","sourcesContent":["body {\n  margin: 0;\n  font-family: \"Helvetica Neue\";\n  //overflow-y: hidden; \n}\n\n.App {\n  font-size: min(calc(7px + 2vmin), 25px);\n  background-color: #000;\n  color: white;\n  min-height: 100vh;\n  /* Fallback for browsers that do not support Custom Properties */\n  min-height: calc(var(--vh, 1vh) * 100);\n}\n\n.link {\n  color: rgba(255, 255, 255, .95);\n  cursor: pointer;\n  text-decoration: none;\n  transition: color .3s;\n}\n\n.link:hover {\n  color: rgba(255, 255, 255, .75);\n  text-decoration: underline;\n}\n\n.pointer {\n  cursor: pointer;\n}\n\n.pointer:hover {\n  font-weight: 300;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
