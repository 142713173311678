export function consoleGreetings() {
    console.log('##########################################################');
    console.log('#   _   _      _ _         _   _                         #');
    console.log('#  | | | | ___| | | ___   | |_| |__   ___ _ __ ___       #');
    console.log("#  | |_| |/ _ \u005C | |/ _ \u005C  | __| '_ \u005C / _ \u005C '__/ _ \u005C      #");
    console.log('#  |  _  |  __/ | | (_) | | |_| | | |  __/ | |  __/_     #');
    console.log('#  |_| |_|\u005C___|_|_|\u005C___/   \u005C__|_| |_|\u005C___|_|  \u005C___( )    #');
    console.log('#  It is said that not all who wander are lost.   |/     #');
    console.log('#  What is it that you are looking for here, my friend?  #');
    console.log('#                                                        #');
    console.log('#  www.clementvanstaen.com | info(a)clementvanstaen.com  #');
    console.log('##########################################################');
}

